import React from 'react';

export const DefaultPage = () =>
{
    return (
        <div>
            <div className='sm-row'>
                <div style={{ margin: "auto" }}>
                    Control SpaceMusic remotely
                </div>
            </div>
            <div className="text-content">
                Open SpaceMusic and find the “Show connection code” button.
                <br/><br/>
                Scan the QR code shown to establish a  remote connection and control SpaceMusic using any phone or tablet.
            </div>
        </div>
  );
}

export default DefaultPage;