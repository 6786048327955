import React, { useEffect, useState } from 'react';
import './App.scss';
import SMHeader from './header';
import DefaultPage from './defaultPage';
import LinkPage, { APIKEY_STR } from './linkPage';

export const App = () =>
{
  var [apikey, setApikey] = useState("");

  useEffect(() => {
    if (window.location.search !== "") {
      const params = new URLSearchParams(window.location.search);

      // apikey
      if (params.has(APIKEY_STR)) {
        const apikey = params.get(APIKEY_STR) || "";
        setApikey(apikey);
      }
    }
  }, []);
  

  return (
    <div className={"App"}>

      <div className="flex-c">

        <SMHeader/>

        <div>
          {apikey !== undefined && apikey !== "" ?
            <LinkPage
              apikey={apikey}
            >
            </LinkPage>
            :
            <DefaultPage></DefaultPage>
          }

        </div>
      </div>
    </div>
  );
}

export default App;