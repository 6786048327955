export const setCookie = (cName, cValue, expDays, hostname) =>
{
    let expires = "";

    if (expDays !== undefined)
    {
        let date = new Date();            
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }

    if (hostname !== undefined)
    {        
        document.cookie = cName + "=" + cValue + "; " + expires + "; domain=" + hostname + "; path=/";
    }
    else
    {
        document.cookie = cName + "=" + cValue + "; " + expires + "; domain=" + window.location.hostname + "; path=/";
    }
}