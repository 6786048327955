import React from 'react';

export const SMHeader = () =>
{
  return (
      <div className="smheader">

          <div className="header-empty">
          </div>

          <div className="header-line grow">
              <img className='sm-row-maxheight' src="sm_logo_off.png"/>
          </div>

      </div>
  );
}

export default SMHeader;