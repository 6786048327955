import React, { useEffect, useState } from 'react';

const cookie = require('./cookie')

export const APIKEY_STR = "apikey";

export const LinkPage = (props) =>
{
    var [redirect, setRedirect] = useState(false);
    var [redirectTimeout, setRedirectTimout] = useState(undefined);

    useEffect(() => {
        // directly set cookie
        doSetCookie();
    }, []);

    useEffect(() => {
        if (redirect !== true) {
            stopTimeout();
        }
    }, [redirect]);

    function getHost() {
        var parts = window.location.hostname.split(".");
        while (parts.length > 2) {
            parts.shift();
        }
        return parts.join(".");
    }

    function stopTimeout() {
        if (redirectTimeout !== undefined) {
            clearTimeout(redirectTimeout);
            setRedirectTimout(undefined);
        }
    }

    function doSetCookie() {
        cookie.setCookie(APIKEY_STR, props.apikey, undefined, getHost());
        doRedirect();
    }

    function doRedirect() {
        const params = new URLSearchParams(window.location.search);
        params.delete(APIKEY_STR);
        window.location = `https://remote.spacemusic.com${params.length > 0 ? "?" : ""}${params.toString()}${window.location.hash}`;
    }


    return (
        <div>
            {/* <div className='sm-row'>
                <div style={{ margin: "auto" }}>
                    Linking to SpaceMusic
                </div>
            </div> */}
        </div>
    );
}

export default LinkPage;